<template>
  <div class="box">
    <div class="payApplyTitle">
      <van-nav-bar
        fixed
        title="查看详情"
        left-arrow
        @click-left="onClickLeft"
      />
    </div>
    <div class="payMain">
      <!-- 发起人信息 -->
      <van-form validate-trigger="onSubmit" colon>
        <div class="block-wrap">
          <div class="personTitle titleA">基本信息</div>
          <van-cell title="申请单号" :value="listPayUser.billNo" />
          <van-cell title="申请人">
            <template #right-icon>
              <span style="color: #ff6a3b">{{ listPayUser.createName }}</span>
            </template>
          </van-cell>
          <van-cell title="申请人所属部门">
            <template #right-icon>
              <span style="color: #ff6a3b">{{
                listPayUser.appDepartmentName
              }}</span>
            </template>
          </van-cell>
          <van-cell title="所属日期" :value="listPayUser.billMonth" />
          <van-cell title="所属合同" :value="listPayUser.contractName" />
          <van-cell title="开票公司" :value="listPayUser.companyName" />
          <van-cell title="所属客户" :value="listPayUser.invoiceCompany" />
          <van-cell title="归属部门" :value="listPayUser.departmentName" />
          <van-cell title="城市/职场" :value="listPayUser.cityWorkName" />
          <van-cell title="含税总金额">
            <template #right-icon>
              <span style="color: #ff6a3b">¥{{ listPayUser.totalAmount }}</span>
            </template>
          </van-cell>
          <div v-for="(items, ind) of listPayUser.attachments" :key="ind">
            <van-cell title="附件">
              <template #right-icon>
                <span>
                  <a style="color: #ff6a3b; width: 80%" :href="items.fileUrl">{{
                    items.fileName
                  }}</a>
                </span>
              </template>
            </van-cell>
          </div>
        </div>
        <div class="detailTitle titleA">发票信息</div>
        <div>
          <div class="expDetail">
            <div class="detailMain">
              <van-cell title="待开票类型包含" :value="listPayUser.typeName" />
              <van-cell title="开票金额总计">
                <template #right-icon>
                  <span style="color: #ff6a3b"
                    >¥{{ listPayUser.totalAmount }}</span
                  >
                </template>
              </van-cell>
              <van-cell title="名称" :value="listPayUser.companyName" />
              <van-cell
                title="发票类型"
                :value="invoiceType[listPayUser.invoiceType]"
              />
              <van-cell
                title="纳税人识别号"
                :value="listPayUser.dutyParagraph"
              />
              <van-cell title="地址" :value="listPayUser.address" />
              <van-cell title="电话" :value="listPayUser.phone" />
              <van-cell title="开户行" :value="listPayUser.bankName" />
              <van-cell title="账户" :value="listPayUser.bankNo" />
              <van-cell title="不含税金额">
                <template #right-icon>
                  <span style="color: #969799"
                    >¥{{ listPayUser.totalTaxPrice }}</span
                  >
                </template>
              </van-cell>
              <van-cell title="税率">
                <template #right-icon>
                  <span style="color: #969799">{{ listPayUser.taxRate }}%</span>
                </template>
              </van-cell>
              <van-cell title="总税额">
                <template #right-icon>
                  <span style="color: #969799"
                    >¥{{ listPayUser.taxAmount }}</span
                  >
                </template>
              </van-cell>
              <van-cell title="价税合计">
                <template #right-icon>
                  <span style="color: #969799"
                    >¥{{ listPayUser.totalAmount }}</span
                  >
                </template>
              </van-cell>
              <van-cell title="开票税目" :value="listPayUser.invoiceTaxItem" />
              <van-cell title="备注" :value="listPayUser.remark" />
            </div>
          </div>
        </div>
      </van-form>
    </div>
    <div class="detailTitle titleA">审批流</div>
    <van-steps direction="vertical" :active="flowIndex" active-color="#FF7E3B">
      <van-step v-for="(item, i) in progress" :key="i">
        <van-row>
          <van-col span="5">{{ item.approverName }}</van-col>
          <van-col style="color: #333333; font-size: 14px;" span="6">{{ statusMap[item.detailStatus] }}</van-col>
          <van-col style="color: #bdbdbd; font-size: 12px;text-align: right;" span="12">{{ item.approverDate }}</van-col>
        </van-row>
        <p style="margin: 5px 0; color: #333333" v-show="item.remark">备注：</p>
        <p class="remarkBox" v-show="item.remark">{{ item.remark }}</p>
      </van-step>
    </van-steps>
    <!-- 注意后期要加上status -->
    <!-- v-if="status === 2" -->
    <div class="btnBox" v-if="status === 2">
      <van-button
        plain
        class="rejects"
        color="#FF7E3B"
        size="small"
        @click.stop="hanldeApproval(2)"
        >驳回
      </van-button>
      <van-button
        plain
        class="rejects"
        size="small"
        color="#FF7E3B"
        @click="handleTransfer()"
        >转审
      </van-button>
      <van-button
        class="pass"
        color="#FF7E3B"
        size="small"
        @click.stop="hanldeApproval(1)"
        >审核通过
      </van-button>
    </div>
    <van-dialog
      v-model="dialogApproval"
      :title="approvalTitle"
      show-cancel-button
      @confirm="sendApproval"
    >
      <van-field
        type="textarea"
        v-model="remark"
        label="备注"
        placeholder="请输入备注"
      />
    </van-dialog>
    <el-dialog
      :close-on-click-modal="false"
      show-cancel-button
      :visible.sync="showPickerl"
      title="转审"
      width="85%"
      custom-class="zhuanshen"
      center
    >
      <span class="approver-wrap">
        <p class="approver-title">审批人</p>
        <el-select
          class="approver-content"
          filterable
          :clearable="showClose"
          ref="select"
          @hook:mounted="cancalReadOnly"
          @visible-change="cancalReadOnly"
          @blur.native.capture="onblur"
          placeholder="请选择"
          v-model="approverId"
          style="border: none"
        >
          <el-option
            v-for="item in approverList"
            :key="item.id"
            :label="item.text + item.phone"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </span>
      <div class="approver-btn-wrap">
        <el-button @click="showPickerl = false" class="approver-btn-cancel">取消</el-button>
        <el-button type="primary" @click="onConfirmss" class="approver-btn-ok">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import "../../styles/reimburse.css";
import {
  getInvoiceDetail, // 发票审核详情
  billProcess, // 单据审批进度（进度条）
  auditPass, // 审批同意
  auditReject, // 审批驳回
  getAllPerson, //审批人自选列表
  referral, //转审
} from "../../api/reimburse";
import {
  NavBar,
  Dialog,
  DatetimePicker,
  Popup,
  Form,
  Field,
  Toast,
  Uploader,
  Overlay,
  Button,
  Radio,
  Picker,
  Grid,
  GridItem,
  Cell,
  Collapse,
  CollapseItem,
  Step,
  Steps,
  Col, Row
} from "vant";
Vue.use(NavBar)
  .use(DatetimePicker)
  .use(Dialog)
  .use(Popup)
  .use(Toast)
  .use(Form)
  .use(Overlay)
  .use(Collapse)
  .use(CollapseItem)
  .use(Step)
  .use(Steps)
  .use(Uploader)
  .use(Field)
  .use(Button)
  .use(Radio)
  .use(Picker)
  .use(Grid)
  .use(GridItem)
  .use(Col)
  .use(Row)
  .use(Cell);

export default {
  name: "reimburse",
  data() {
    return {
      showSuccess: false,
      invoiceType: ["增值税普通发票", "增值税专用发票"],
      dialogApproval: false,
      statusMap: {
        0: "待审核",
        1: "审批中",
        3: "已同意",
        2: "已驳回",
        4: "已转审",
      },
      showPickerl: false,
      invoiceId: "",
      showClose: false,
      Person: "",
      progress: [],
      approverList: [],
      status: 1,
      approverId: "",
      flowIndex: -1,
      listPayUser: {},
      remark: "",
      approvalTitle: '',
      handleType: '',
    };
  },
  mounted() {
    let query = this.$route.query;
    // 查看是否有内容
    this.invoiceId = query.invoiceId;
    this.status = query.status;
    if (this.invoiceId) {
      this.getView();
    } else {
      // console.log("打开错误");
    }
  },
  methods: {
    // 失去焦点
    onblur() {
      setTimeout(() => {
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          //判断iPhone|iPad|iPod|iOS
          // this.$refs.select.blur();
        }
        this.showClose = false;
        var scrollHeight =
          document.documentElement.scrollTop || document.body.scrollTop || 0;
        window.scrollTo(0, Math.max(scrollHeight - 1, 0));
      }, 100);
    },
    cancalReadOnly(onOff) {
      this.showClose = true;
      this.$nextTick(() => {
        if (!onOff) {
          const input = document.querySelector(".el-input__inner");
          input.removeAttribute("readonly");
        }
      });
    },
    handleTransfer() {
      this.approverId = '';
      this.showPickerl = true;
    },
    // 转审确认
    onConfirmss(value) {
      this.Person = value;
      // console.log(this.approverId);
      this.showPickerl = false;
      this.sendReferrals({
        approverId: this.approverId,
        billNo: this.listPayUser.billNo,
      });
    },
    // 获取查看信息
    getView() {
      getInvoiceDetail({ id: this.invoiceId }).then((res) => {
        // console.log(res.resultData);
        this.listPayUser = res.resultData;
      });
      // 审批流进度条
      billProcess({ billId: this.invoiceId, flowModel: 0, module: 5 }).then(
        (r) => {
          r.resultData.details.map((item, i) => {
            if(item.detailStatus == 0 && this.flowIndex === -1) {
              this.flowIndex = i;
              return
            }
          });
          this.progress = [...r.resultData.details];
        }
      );
      // 转审里的审批人
      getAllPerson({}).then((r) => {
        let resultData = [...r.resultData];
        let list = [];
        resultData.map((item) => {
          list.push({
            text: item.personName,
            id: item.personId,
            phone: item.phone,
          });
        });
        this.approverList = list;
      });
    },
    // 转审
    sendReferrals(obj) {
      referral(obj).then((r) => {
        if (r.success) {
          Toast.success("提交成功");
          this.getView();
          this.$router.go(-1);
        }
      });
    },
    // 驳回或通过显示
    hanldeApproval(type) {
      if(type === 2) {
        this.approvalTitle = '驳回'
      } else if(type === 1) {
        this.approvalTitle = '通过'
      }
      this.dialogApproval = !this.dialogApproval;
      this.items = this.listPayUser;
      this.interrogate = this.listPayUser.billNo;
      this.handleType = type;
      this.remark = "";
    },
    // 驳回或通过提交接口
    sendApproval() {
      if (this.handleType == 1) {
        auditPass({ billNo: this.items.billNo, remark: this.remark }).then(
          (r) => {
            if (r.success) {
              Toast.success("提交成功");
              this.$router.go(-1);
            } else {
              Toast.fail(r.resultMsg);
              this.getView();
            }
          }
        );
      } else {
        auditReject({ billNo: this.items.billNo, remark: this.remark }).then(
          (r) => {
            if (r.success) {
              Toast.success("提交成功");
              this.$router.go(-1);
            } else {
              Toast.fail(r.resultMsg);
              this.getView();
            }
          }
        );
      }
    },

    // 顶部返回按钮
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss">
.block-wrap{
  margin-bottom: 10px;
}
.zhuanshen {
  position: fixed;
  height: 198px;
  top: 30%;
  left: 50%;
  width: 320px;
  overflow: hidden;
  font-size: 16px;
  background-color: #fff;
  border-radius: 16px;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}
.el-dialog__body {
  padding: 0px 0px;
}
.approver-wrap {
  display: inline-block;
  width: 100%;
  display: flex;
  .approver-title {
    width: 100%;
    flex: 2.5;
    text-align: center;
    line-height: 40px;
  }
  .approver-content {
    flex: 7.5;
    width: 100%;
    .el-input__inner {
      border: none;
    }
  }
}
.el-select .el-input .el-select__caret {
  color: white;
}
.el-dialog__headerbtn .el-dialog__close:active {
  color: white;
}
.approver-btn-wrap {
  margin-top: 35px;
  display: flex;
  margin-left: 0px;
  .approver-btn-ok {
    border: 1px solid #ebedf0;
    width: 50%;
    height: 48px;
    flex: 1;
    display: inline-block;
    color: red;
    margin-left: 0px;
    background-color: white;
    border-left: none;
    border-bottom: none;
    border-radius: 0px;
    font-size: 16.5px;
    font-weight: 400;
  }
  .approver-btn-cancel {
    border-radius: 0px;
    border: none;
    border: 1px solid #ebedf0;
    border-bottom: none;
    height: 48px;
    display: inline-block;
    width: 50%;
    flex: 1;
    color: black;
    font-size: 16.5px;
    font-weight: 400;
  }
  .approver-btn-cancel:active {
    color: black;
    background: white;
  }
}
.el-dialog__header {
  padding: 0px 0px 0px;
  padding-top: 26px;
  margin-top: 15px;
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}
.el-dialog__headerbtn .el-dialog__close {
  color: white;
}
.el-dialog--center .el-dialog__body {
  padding: 0px 0px 0px;
  width: 100%;
  height: 100px;
}
</style>
<style lang="scss" scoped>
.van-cell::after {
  border: 0;
}

.van-field {
  display: flex;
}

.expDetail {
  border-radius: 0;
  width: 96%;
  margin: 8px auto;
  background-size: cover;
  border: 0;
  padding: 6px;

  .topTitle {
    margin: 21px 16px;
    font-size: 14px;
    font-weight: 500;
  }
}

.remarkBox {
  width: 95%;
  height: 67px;
  background: #fbfbfb;
  border: 0.5px solid #e3e3e3;
  border-radius: 2px;
  margin-top: 12px;
  padding: 5px;
}

.btnBox {
  display: flex;
  justify-content: center;
  padding: 47px 0;
}

.rejects {
  width: 75px;
  height: 44px;
  border: 1px solid #ff7e3b;
  border-radius: 2px;
  margin-right: 16px;
}

.pass {
  width: 180px;
  height: 44px;
  background: #ff7e3b;
  border-radius: 2px;
}
</style>>

